<template>

  <div class="row" ref="contactUsView">

    <div class="col-12">

      <card>
        <div slot="header">
          <div class="header-content">
            <div class="form-title">
              <h4 class="card-title">
                {{ formTitle }}
              </h4>
            </div>
            <div class="header-actions">
              <router-link   v-tooltip.top-center="'Go back'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/clients'">
                <span class="btn-label px-2">
                    <i class="fa fa-arrow-left" style="font-size: 30px"></i>
                  </span>
              </router-link>
            </div>
          </div>
        </div>

        <div id="contactus-message" class="card-body">
          <div class="container">
<!--            <div class="row contact-info">-->
<!--              <div class="col-12 col-md-6 col-lg-6">-->
<!--                <div class="typo-line">-->
<!--                  <p>-->
<!--                    <span class="category">Name</span>-->
<!--                    {{ getValueOrDash(clientDetails, 'name') }}-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="typo-line">-->
<!--                  <p>-->
<!--                    <span class="category">Email</span>-->
<!--                    {{ getValueOrDash(clientDetails, 'email') }}-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="typo-line">-->
<!--                  <p>-->
<!--                    <span class="category">Location</span>-->
<!--                    {{ getValueOrDash(clientDetails, 'location') }}-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-12 col-md-6 col-lg-6">-->
<!--                <div class="typo-line">-->
<!--                  <p>-->
<!--                    <span class="category">Phone</span>-->
<!--                    {{ getValueOrDash(clientDetails, 'phone') }}-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="typo-line">-->
<!--                  <p>-->
<!--                    <span class="category">Project Name</span>-->
<!--                    {{ getValueOrDash(clientDetails, 'project_name') }}-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="typo-line">-->
<!--                  <p>-->
<!--                    <span class="category">Website</span>-->
<!--                    {{ getValueOrDash(clientDetails, 'website') }}-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          <div class="client-section">
            <label>Client Brief</label>
            <div class="row">
              <div v-for="(client, index) in clientDetails.client_brief" class="project-detail col-md-6">
                <label>{{ client.question ? client.question : '-' }}</label>
                <div>{{ client.answer }}</div>
              </div>
            </div>
          </div>
            <div class="client-section">
              <label>Client Project</label>
              <div class="row">
                <div v-for="(project, index) in clientDetails.project" class="project-detail col-md-6">
                  <label>{{ project.question }}</label>
                  <div>{{ project.answer }}</div>
                </div>
              </div>
            </div>
<!--            <div v-for="(project, index) in clientDetails.projects" class="projects-collapse">-->
<!--              <el-collapse>-->
<!--                <el-collapse-item :title="'Project ' + (index + 1) " name="1">-->
<!--                  <div class="project-detail">-->
<!--                    <label>about the project / campaign/ product</label>-->
<!--                    <div>{{ project.background_information }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>challenges</label>-->
<!--                    <div>{{ project.challenges }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>business objective</label>-->
<!--                    <div>{{ project.business_objective }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>target audience</label>-->
<!--                    <div>{{ project.target_audience }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>competitors</label>-->
<!--                    <div>{{ project.competitors }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>usp</label>-->
<!--                    <div>{{ project.usp }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>expected deliverables</label>-->
<!--                    <div>{{ project.expected_deliverables }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>particular request</label>-->
<!--                    <div>{{ project.particular_request }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>budget</label>-->
<!--                    <div>{{ project.budget }}</div>-->
<!--                  </div>-->
<!--                  <div class="project-detail">-->
<!--                    <label>mandatories</label>-->
<!--                    <div>{{ project.mandatories }}</div>-->
<!--                  </div>-->
<!--                </el-collapse-item>-->
<!--              </el-collapse>-->
<!--            </div>-->
          </div>
        </div>

        <div class="card-footer text-right">

        </div>
      </card>


    </div>

  </div>
</template>
<script>
import {Collapse, CollapseItem, Option, Select, Table, TableColumn} from 'element-ui';
export default {

  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,

      clientDetails: null,

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.contactUsView
    });

    this.id = this.$route.params['id'];
    this.formTitle = "Client Details";
    if (this.id !== undefined) {
      this.getClient();
    } else {
      this.$notify({
        message: "Client Not Found",
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {

    getClient() {
      console.log(this.id)
      this.axios.get("clients/get/" + this.id).then((response) => {
        this.clientDetails = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Client Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    },
  }
}
</script>

<style>
.header-content{
  display: flex;
  justify-content: space-between;
}
.typo-line{
  padding-left: 92px;
  margin-bottom: 20px;
}
.contact-info{
  margin-bottom: 20px;
}
.category{
  color: #888888;
}
/*.message-content{*/
/*  font-size: 16px !important*/
/*}*/
.projects-collapse .project-detail{
  border: 1px solid #f1ecec;
  padding: 10px;
}
.client-section{
  border: 1px solid #f3eeee;
  padding: 10px;
  margin-top: 20px;
}
.project-detail{
  margin-top: 15px;
}
</style>
